<template>
    <b-row>

        <b-col
                cols="12"
                md="6"
                class=" mb-1"
        >
            <div class="pb-2 mt-3 table-top-toolbar">

            <template v-if="mlm">
                <tree :tree-data="mlm" child-prop="mlm_agent_data" label-prop="name"></tree>
            </template>

            </div>

        </b-col>

    </b-row>
</template>

<script>
    import {

        BTable, BDropdownItem, BDropdown, BPagination, BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        BButton,

    } from 'bootstrap-vue'

    import vSelect from 'vue-select'

    import {MLM_PREFIX as PREFIX, MODULE_PREFIX} from './../moduleHelper'



    import tree from './../includes/tree'

    export default {
        components: {

            BTable, BDropdownItem, BDropdown, BPagination, BMedia,
            BMediaAside,
            BMediaBody,
            BAvatar,
            BButton,
            vSelect,
            tree,
        },
        props: ['moduleItem'],
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

                tabItem: false,

                mlm:false,

                tree: {
                    label: "A cool folder",
                    children: [
                        {
                            label: "A cool sub-folder 1",
                            children: [
                                { label: "A cool sub-sub-folder 1" },
                                { label: "A cool sub-sub-folder 2" }
                            ]
                        },
                        { label: "This one is not that cool" }
                    ]
                }
            }
        },

        methods: {
            getPageData(){
                        this.async('get', '/' + this.MODULE_PREFIX + '_' + this.PREFIX, {
                            params: {
                                ['user_id']: this.moduleItem.id,
                            }
                        }, function (resp) {
                            this.mlm = resp.data;
                        });
            }

        },
        created() {

            this.getPageData();
            // this.MODULE_PREFIX = this.is_deal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;

            // if (this.$can('edit', this.MODULE_PREFIX+'.tab_' + this.PREFIX + 's')) {
            //     this.columnsDefs.push({label: 'label_action', key: 'actions'});
            // }
            // this.refreshDataTable();
        },
    }
</script>
<style lang="scss">
    .line-actions {
        position: absolute;
        right: 0;
        top: 0;
    }

    .email-media-list {
        width: 100%;
    }

.tree-line{
    height:30px;
    align-items: center;
display: flex;
    padding: 4px 10px;
}
</style>